import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import MenuIcon from "@mui/icons-material/MoreVert";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import Lottie from "lottie-react";
import React, { useState } from "react";
import failedEllipse from "../assets/images/failed_ellipse.svg";
import successEllipse from "../assets/images/success_ellipse.svg";
import { theme } from "./theme";
import { styled } from "@mui/material/styles";
import Finbox from "../assets/images/finbox.svg";

export const PoweredBy = (props) => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    spacing={0.75}
  >
    <Typography fontSize={10} textAlign="right">
      Powered by
    </Typography>
    <img
      src={props.image}
      alt={props.alt}
      style={{
        maxWidth: "120px",
        height: "100%",
      }}
    />
  </Stack>
);

export const BaseContainer = (props) => (
  <Container
    maxWidth={"sm"}
    sx={{
      overflowY: "auto",
      backgroundColor: props.backgroundcolor,
      px: props.backgroundcolor ? "0.5rem" : "1rem",
      pt: "4rem",
      pb: "0.5rem",
      position: "relative",
      height: "100%",
      width: "100%",
      minHeight: "640px",
      minWidth: "100px",
      [theme.breakpoints.up("sm")]: {
        boxShadow: `${theme.palette.primary.main} 0px 0px 50px -30px`,
      },
    }}
    {...props}
  >
    <Stack height={"100%"} justifyContent="space-between">
      <>{props.children}</>
      <Stack marginTop="1.5rem" marginBottom="6px">
        <PoweredBy image={Finbox} alt={"FinBox"} />
      </Stack>
    </Stack>
  </Container>
);

export const PrimaryButton = styled(Button)({
  fontSize: 16,
  padding: "13px",
  width: "100%",
  textTransform: "none",
  fontWeight: 500,
});

export const SuccessComponent = ({ title, success }) => {
  return (
    <>
      <Stack alignItems={"center"} spacing={3}>
        <img
          src={success ? successEllipse : failedEllipse}
          alt={success ? "Success" : "Error"}
        ></img>
        <Typography
          sx={{
            width: "100%",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
      </Stack>
    </>
  );
};

export const TitleBar = ({ title, onClose, showBack, onBack, showMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar
      sx={{
        maxWidth: "sm",
        [theme.breakpoints.up("sm")]: {
          right: "auto",
          marginLeft: "-1.5rem",
        },
        backgroundImage: "linear-gradient(180deg, #F70000 0%, #CC0000 100%);",
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          sx={{ px: 0 }}
          onClick={() => onBack()}
          color="inherit"
        >
          {showBack && <ArrowBack sx={{ mr: 1 }} />}
        </IconButton>
        <Typography
          fontSize="1.125rem"
          component="div"
          fontWeight={600}
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          {title}
        </Typography>
        {showMenu && (
          <div>
            <IconButton
              size="large"
              onClick={handleMenu}
              color="inherit"
              sx={{ px: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>Help</MenuItem>
              <MenuItem>FAQs</MenuItem>
              <MenuItem>T&amp;C</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};
export const WaitState = ({ gif, title, desc, height, width }) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Lottie animationData={gif} loop={true} />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {title && (
          <Typography align="center" variant="h6" fontWeight={500}>
            {title}
          </Typography>
        )}
        {desc && (
          <Typography align="center" variant="body2">
            {desc}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
