import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { BaseContainer, PrimaryButton, TitleBar } from "../components/common";
import AppContext from "../contexts/AppContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
const PartnerPush = () => {
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(AppContext);
  const [name, setName] = useState("");
  const [dob, setDob] = useState(null);
  const [fname, setFname] = useState("");
  const history = useHistory();

  const getPartnerdata = async () => {
    const requestBody = {
      name: name,
      dob: format(dob, "dd-MM-yyyy"),
      // fatherName: fname,
    };
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        token: context.token,
      },
      body: JSON.stringify(requestBody),
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/kyc/okyc/partnerPush",
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      history.replace("/selfie");
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };
  return (
    <BaseContainer backgroundcolor="background.paper">
      <TitleBar title={"Partner Push"} />
      <Stack justifyContent="space-between" height="100%" py={1}>
        <Stack spacing={1}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "15px",
              marginTop: "10px",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography sx={{ fontSize: "14px" }}>Name :</Typography>
              <TextField
                size="small"
                id=""
                type="text"
                sx={{ width: "100%", marginTop: "" }}
                value={name}
                placeholder="Your Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              marginTop="10px"
            >
              <Typography sx={{ fontSize: "14px" }}>Dob :</Typography>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                marginTop="10px"
              ></Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="DD / MM / YYYY"
                  inputFormat="dd/MM/yyyy"
                  value={dob}
                  onChange={(e) => {
                    setDob(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            {/* <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              marginTop="10px"
            >
              <Typography sx={{ fontSize: "14px" }}>Father's Name :</Typography>
              <TextField
                size="small"
                id=""
                type="text"
                sx={{ width: "100%", marginTop: "" }}
                value={fname}
                placeholder="Father's name"
                onChange={(e) => {
                  setFname(e.target.value);
                }}
              />
            </Grid> */}
          </Box>
        </Stack>
        <Stack spacing={2}>
          <PrimaryButton variant="contained" onClick={getPartnerdata}>
            {false ? (
              <CircularProgress size={30} color="secondary" />
            ) : (
              "Submit"
            )}
          </PrimaryButton>
        </Stack>
      </Stack>
    </BaseContainer>
  );
};
export default PartnerPush;
