import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { theme } from "./components/theme";
import AdhaarKyc from "./modules/AdhaarKyc";
import AdhaarVerification from "./modules/AdhaarVerification";
import Error from "./modules/Error";
import Liveness from "./modules/Liveness";
import Selfie from "./modules/Selfie.js";
import Success from "./modules/Success";
import GetToken from "./modules/GetToken";
import { AppProvider } from "./contexts/AppContext";
import PartnerPush from "./modules/PartnerPush";
import DigilokerError from "./modules/DigilockerError";
import SessionExpired from "./modules/SessionExpired";
import SuccessLottie from "./components/SuccessLottie";
import KycStatus from "./modules/KycStatus";
import AadhaarConsent from "./modules/AadhaarConsent";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <SnackbarProvider maxSnack={2}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route exact path="/journey/:id">
                <GetToken />
              </Route>
              <Route exact path="/digilocker-error">
                <DigilokerError />
              </Route>
              <Route exact path="/selfie">
                <Selfie />
              </Route>
              <Route path="/liveness">
                <Liveness />
              </Route>
              <Route path="/aadhaar-verify">
                <AdhaarVerification />
              </Route>
              <Route path="/aadhaar-kyc">
                <AdhaarKyc />
              </Route>
              <Route path="/success">
                <Success
                  title="Your selfie has been successfully uploaded"
                  redirect={true}
                />
              </Route>
              <Route path="/success-aadhaar">
                <Success
                  title={
                    "Aadhaar number has been verified successfully. Please note your KYC has been completed."
                  }
                />
              </Route>
              <Route path="/error">
                <Error />
              </Route>
              <Route path="/partner-push">
                <PartnerPush />
              </Route>
              <Route path="/error-aadhaar">
                <Error
                  title={
                    "System could not verify the Aadhaar details you have entered from UIDAI, please retry after sometime."
                  }
                />
              </Route>
              <Route exact path="/session-expired">
                <SessionExpired />
              </Route>
              <Route exact path="/success-kyc">
                <SuccessLottie title="Your KYC has been done successfully" />
              </Route>
              <Route exact path="/kyc-status">
                <KycStatus />
              </Route>
              <Route exact path="/aadhaar-consent">
                <AadhaarConsent />
              </Route>
            </Switch>
          </Router>
        </SnackbarProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
