import { Stack, Typography, Box } from "@mui/material";
import { BaseContainer, TitleBar } from "../components/common";
import SessionExpiredImg from "../assets/images/session-expired.svg";

const SessionExpired = () => {
  return (
    <BaseContainer>
      <Stack justifyContent="center" height="100%">
        <Box
          component="img"
          sx={{
            height: 253,
            width: 350,
          }}
          alt="Session-Expired-Img"
          src={SessionExpiredImg}
          alignSelf="center"
          mb={5}
        />
        <Stack
          spacing={1}
          justifyContent="space-around"
          alignItems="center"
          px={5}
        >
          <Typography fontSize="28px" fontWeight={600} textAlign="center">
            Session Expired
          </Typography>
          <Typography variant="h6" textAlign="center">
            This digiloker link has expired, Please generate new link to
            continue.
          </Typography>
        </Stack>
      </Stack>
    </BaseContainer>
  );
};

export default SessionExpired;
