import { Stack, Typography, Link } from "@mui/material";
import Lottie from "lottie-react";
import { useSnackbar } from "notistack";
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import adhaarLoader from "../assets/aadhar.json";
import { BaseContainer, TitleBar } from "../components/common";
import AppContext from "../contexts/AppContext";

const AdhaarVerification = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(AppContext);

  const getLivenessStatus = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/kyc/okyc/kycStatus?service_name=kyc",
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      return json;
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const pollWaitStatus = () => {
    setTimeout(async () => {
      let { KYCStatus } = await getLivenessStatus();
      if (KYCStatus === "SUCCESS") {
        history.replace("/success-aadhaar");
      } else if (KYCStatus === "FAILURE") {
        history.replace("/error-aadhaar");
      } else {
        pollWaitStatus();
      }
    }, 4000);
  };

  useEffect(() => {
    pollWaitStatus();
  }, []);

  return (
    <BaseContainer>
      <TitleBar title={"Paperless Aadhaar KYC"} />
      <Stack height="100%" justifyContent="space-around">
        <Stack spacing={3} px={5} height="60%" justifyContent="space-around">
          <Lottie animationData={adhaarLoader} loop={true} />
          <Typography fontSize="20px" fontWeight="500" textAlign="center">
            Processing your Aadhaar information
          </Typography>
        </Stack>
        {false && (
          <Stack
            spacing={1}
            justifyContent="space-around"
            alignItems="center"
            px={5}
          >
            <Typography variant="body2" textAlign="center">
              There seems to be a delay in Aadhaar verification, please wait or
              you can start again
            </Typography>
            <Link href="/">Restart KYC</Link>
          </Stack>
        )}
      </Stack>
    </BaseContainer>
  );
};

export default AdhaarVerification;
