import React from "react";
import Lottie from "lottie-react";
import successLottie from "../assets/images/lottie/Success-filled.json";
import { BaseContainer, PrimaryButton, TitleBar } from "./common";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import AppContext from "../contexts/AppContext";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

const SuccessLottie = ({ title }) => {
  const context = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const getRedirect = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/session/redirect`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      window.open(json.url, "_self");
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getRedirect();
    }, 5000);
  }, []);

  return (
    <BaseContainer>
      <TitleBar title={"Paperless Aadhaar KYC"} />
      <Stack
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            width: "40%",
            display: "block",
            marginTop: "25%",
          }}
        >
          <Lottie animationData={successLottie} loop={false} />
        </Box>
        <Typography variant="h6" textAlign="center">
          {title}
        </Typography>
        <PrimaryButton
          style={{ margin: "auto 0 15px 0" }}
          variant="contained"
          onClick={getRedirect}
        >
          Continue
        </PrimaryButton>
      </Stack>
    </BaseContainer>
  );
};

export default SuccessLottie;
