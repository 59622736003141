import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Checkbox,
  useTheme,
  Link,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  Popover,
} from "@mui/material";
import { useSnackbar } from "notistack";

import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import loop from "../assets/images/loop.svg";
import speaker from "../assets/images/sound_speakers.svg";
import verifyTick from "../assets/images/verifyTick.svg";
import { BaseContainer, PrimaryButton, TitleBar } from "../components/common";
import AppContext from "../contexts/AppContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const addLeadingZeros = (num, totalLength) => {
  return String(num).padStart(totalLength, "0");
};

const ConsentScreen = ({ setCurrentScreen }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(AppContext);

  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState();

  const [languages, setLanguages] = useState(["ENGLISH"]);
  const [consentLanguage, setConsentLanguage] = useState("ENGLISH");
  const [consentData, setConsentData] = useState("");
  const [consentHeaders, setConsentHeaders] = useState("");
  const [consentLoading, setConsentLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  const getConsent = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/kyc/okyc/kotakConsent",
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      setConsentData(json);
      setConsentHeaders(json.kotakRequestObj);
      setAudio(json.file);
      getConsentLanguages(json);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const play = () => {
    setPlaying(true);
    audio.play();
  };

  const pause = () => {
    setPlaying(false);
    audio.pause();
  };

  const getConsentLanguages = async (consentData) => {
    if (!consentData) return;
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        token: context.token,
      },
      body: JSON.stringify(consentData.kotakRequestObj),
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/kyc/okyc/kotakLang",
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      setLanguages(json.languages);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const changeLanguage = async (consentLanguage) => {
    if (!consentHeaders) return;
    if (!consentLanguage) return;
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        token: context.token,
      },
      body: JSON.stringify(consentHeaders),
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/kyc/okyc/kotakConsentByLang?language=${consentLanguage}`,
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      setConsentData(json);
      setAudio(json.file);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const submitConsent = async () => {
    if (!consentHeaders) return;
    setConsentLoading(true);
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        token: context.token,
      },
      body: JSON.stringify(consentHeaders),
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        `/kyc/okyc/kotakSubmitConsent?consent=yes`,
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      console.log(json);
      setCurrentScreen("captcha");
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    setConsentLoading(false);
  };

  useEffect(() => {
    getConsent();
  }, []);

  return (
    <Stack justifyContent="space-between" height="100%" py={1}>
      <Stack spacing={1}>
        <Box
          sx={{
            backgroundColor: "white",
            padding: "15px",
            marginTop: "10px",
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography fontSize={"16px"} fontWeight={500}>
              Aadhaar Consent
            </Typography>
            <Select
              size="small"
              value={consentLanguage}
              onChange={(e) => {
                setConsentLanguage(e.target.value);
                changeLanguage(e.target.value);
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Box
            sx={{
              border: "1px solid " + theme.palette.background.paper,
              padding: "8px",
              minHeight: "250px",
              overflow: "scroll",
              marginTop: "10px",
            }}
          >
            {consentData.consentMessage ? (
              <Typography variant="body2">
                {consentData.consentMessage}
              </Typography>
            ) : (
              <CircularProgress size={30} color="secondary" />
            )}
          </Box>
          <Grid
            container
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{ marginTop: "10px" }}
          >
            <Grid
              backgroundColor="background.paper"
              sx={{
                borderRadius: "5px",
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            >
              <img
                style={{ width: "100%" }}
                src={speaker}
                alt="loop"
                onClick={playing ? pause : play}
              ></img>
            </Grid>
            <Typography py={1} variant="body2" color="text.secondary">
              I would like to listen Aadhaar terms
            </Typography>
          </Grid>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={1}>
            <Checkbox
              color="error"
              onChange={() => setChecked(!checked)}
              checked={checked}
              sx={{ padding: "0" }}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: "14px" }}>
              I authorize Kotak Mahindra Bank Ltd. to collect my Paperless
              Aadhaar Information.
            </Typography>
          </Grid>
        </Grid>
        <PrimaryButton
          variant="contained"
          disabled={consentLoading || !checked}
          onClick={submitConsent}
        >
          {consentLoading ? (
            <CircularProgress size={30} color="secondary" />
          ) : (
            "Submit"
          )}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};

const CaptchaScreen = ({ hvSessionId, setData, setCurrentScreen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const [adhaarNumber, setAdhaarNumber] = useState("");

  const [adhaarError, setAdhaarError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [apiError, setApiError] = useState("");

  const [captchaData, setCaptchaData] = useState({});
  const [aadhaarMask, setAadhaarMask] = useState("");
  const [showAadhaar, setShowAadhaar] = useState(false);

  const hideAll = useRef();

  const [popoverContent, setPopoverContent] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event, content = "") => {
    setPopoverContent(content);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const setCaptchaDetails = (data) => {
    setCaptchaData(data);
    setData(data);
  };

  const getCaptcha = async () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/kyc/okyc/getCaptcha",
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      setCaptcha("");
      setCaptchaDetails(json);
    } else {
      enqueueSnackbar(
        "System could not get captcha from UIDAI, please retry after sometime.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
    }
    setLoading(false);
  };

  const onAadhaarChange = (e) => {
    if (e.target.value.length) {
      setAdhaarError("");
    }
    if (e.target.value.length > 12) {
      e.preventDefault();
      return;
    }
    clearInterval(hideAll.current);
    const maskValue = e.target.value;
    const aadhaarValue = adhaarNumber;
    let showLength = 1;
    let offset = maskValue.length - aadhaarValue.length;
    if (offset > 0) {
      setAdhaarNumber(
        aadhaarValue +
          maskValue.substring(aadhaarValue.length, aadhaarValue.length + offset)
      );
    } else if (offset < 0) {
      setAdhaarNumber(aadhaarValue.substring(0, aadhaarValue.length + offset));
    }
    setAadhaarMask(
      maskValue.substring(0, maskValue.length - showLength).replace(/./g, "X") +
        maskValue.substring(maskValue.length - showLength, maskValue.length)
    );
    // Set the timer
    hideAll.current = setTimeout(() => {
      setAadhaarMask(maskValue.replace(/./g, "X"));
    }, 1000);
  };

  const requestOtp = async () => {
    if (!captcha || captcha.length !== 6) {
      setCaptchaError("Invalid captcha code entered. Please try again.");
      return;
    }
    if (adhaarNumber.length !== 12 || isNaN(adhaarNumber)) {
      setAdhaarError("Please enter a valid Aadhaar number to proceed further.");
      return;
    }
    if (!hvSessionId) return;
    setRequestLoading(true);
    const requestBody = {
      hvSessionId: hvSessionId,
      captcha: captcha,
      aadhaarNo: adhaarNumber,
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(requestBody),
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/kyc/okyc/getOTP",
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      setCurrentScreen("otp");
    } else {
      if (json.statusCode === "11104") {
        setCaptchaError("Invalid captcha code entered. Please try again.");
      } else if (json.statusCode === "11103") {
        setAdhaarError(
          "Please enter a valid Aadhaar number to proceed further."
        );
      } else if (json.statusCode === "12103") {
        setApiError(
          "Your request cannot be processed now due to technical issue. Please try again later."
        );
      } else {
        setApiError(json.error);
      }
    }
    setRequestLoading(false);
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  return (
    <Stack justifyContent="space-between" height="100%" py={1}>
      <Stack spacing={1}>
        <Box
          sx={{
            backgroundColor: "white",
            padding: "15px",
          }}
        >
          <Stack>
            <Typography fontSize={"16px"} fontWeight={500}>
              Enter Captcha
            </Typography>
          </Stack>
          <Stack mt={2}>
            {loading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                minHeight="78px"
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                container
                justifyContent={"center"}
                sx={{ width: "50%", margin: "auto" }}
              >
                <Grid item xs={12}>
                  <img
                    style={{ margin: "auto", width: "100%" }}
                    src={"data:image/png;base64, " + captchaData?.captchaImage}
                    alt="captcha"
                  ></img>
                </Grid>
              </Grid>
            )}
          </Stack>
          <Stack>
            <Grid container alignItems="center" justifyContent={"center"}>
              <Grid item>
                <Typography marginRight={-10} color="#656F7A">
                  Captcha
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid
                item
                backgroundColor="background.paper"
                sx={{
                  borderRadius: "5px",
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              >
                <img
                  style={{ width: "100%", cursor: "pointer" }}
                  src={loop}
                  onClick={getCaptcha}
                  alt="loop"
                  onMouseEnter={(event) =>
                    handlePopoverOpen(event, "Reload Captcha")
                  }
                  onMouseLeave={handlePopoverClose}
                ></img>
              </Grid>
            </Grid>
          </Stack>
          <TextField
            size="small"
            sx={{ width: "100%", marginTop: "20px" }}
            value={captcha}
            placeholder="Captcha Text"
            onChange={(e) => {
              if (e.target.value.length) {
                setCaptchaError("");
              }
              setCaptcha(e.target.value);
            }}
          />
          {captchaError && (
            <Typography fontSize={"12px"} color="error" mt={0.5}>
              {captchaError}
            </Typography>
          )}
          {false && (
            <Grid container alignItems={"center"}>
              <Grid item xs={0.5}>
                <img src={verifyTick} alt="check" />
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={"12px"} color="text.success">
                  VERIFIED
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            padding: "15px",
            marginTop: "10px",
          }}
        >
          <Stack>
            <Typography fontSize={"16px"} fontWeight={500}>
              Enter Aadhaar Number
            </Typography>
          </Stack>
          <FormControl sx={{ width: "100%", marginTop: "20px" }}>
            <OutlinedInput
              size="small"
              id="aadhaar"
              value={showAadhaar ? adhaarNumber : aadhaarMask}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  requestOtp();
                }
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder="12 digit Aadhaar Number"
              onChange={onAadhaarChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle Adhaar visibility"
                    // onClick={() => {
                    //   setShowAadhaar(!showAadhaar);
                    // }}
                    onMouseEnter={(event) =>
                      handlePopoverOpen(
                        event,
                        showAadhaar ? "Hide Aadhaar" : "Show Aadhaar"
                      )
                    }
                    onMouseLeave={handlePopoverClose}
                    onMouseDown={(event) => {
                      setShowAadhaar(true);
                    }}
                    onMouseUp={(event) => {
                      setShowAadhaar(false);
                    }}
                    edge="end"
                  >
                    {showAadhaar ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {adhaarError && (
            <Typography fontSize={"12px"} color="error" mt={0.5}>
              {adhaarError}
            </Typography>
          )}
        </Box>
        {apiError && (
          <Typography fontSize={"12px"} color="error" mt={0.5}>
            {apiError}
          </Typography>
        )}
      </Stack>
      <PrimaryButton
        variant="contained"
        disabled={requestLoading}
        onClick={() => requestOtp()}
      >
        {requestLoading ? (
          <CircularProgress size={30} color="secondary" />
        ) : (
          "Submit"
        )}
      </PrimaryButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popoverContent}</Typography>
      </Popover>
    </Stack>
  );
};

const OTPScreen = ({ hvSessionId, setCurrentScreen }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(AppContext);

  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [apiError, setApiError] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const [timer, setTimer] = useState(600);
  const [resetInterval, setResetInterval] = useState(0);

  useEffect(() => {
    setTimer(600);
    const interval = setInterval(() => {
      setTimer((timer) => {
        if (timer === 0) {
          clearInterval(interval);
          return 0;
        }
        return timer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [resetInterval]);

  useEffect(() => {
    if (timer === 0) {
      setOtpError("OTP has been expired");
      setApiError(true);
    }
  }, [timer]);

  const history = useHistory();

  const submitOtp = async () => {
    if (!otp || otp.length !== 6) {
      setOtpError("The OTP you have entered is incorrect. Please try again.");
      return;
    }
    if (!hvSessionId) return;
    setOtpLoading(true);
    setApiError(false);
    const requestBody = {
      hvSessionId: hvSessionId,
      otp: otp,
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(requestBody),
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/kyc/okyc/getAadhaar",
      requestOptions
    );
    const json = await res.json();
    setAttempt(attempt + 1);
    console.log(json);
    if (res.ok) {
      history.replace("/aadhaar-verify");
    } else {
      setApiError(true);
      if (json.statusCode === "12203") {
        setOtpError(
          "The OTP you have entered is incorrect. Please restart the KYC."
        );
      } else if (json.statusCode === "12204") {
        setOtpError(
          "Your request cannot be processed now due to technical issue. Please try again later."
        );
      } else if (json.statusCode === "500") {
        setOtpError(
          "Your request cannot be processed now due to technical issue. Please try again later."
        );
      } else {
        // setOtpError(json.error);
        setOtpError("The OTP you have entered is incorrect. Please try again.");
      }
    }
    setOtpLoading(false);
  };

  return (
    <Stack justifyContent="space-between" height="100%" py={1}>
      <Box
        sx={{
          backgroundColor: "white",
          padding: "15px",
          marginTop: "10px",
        }}
      >
        <Stack>
          <Typography fontSize={"18px"} fontWeight={500}>
            Enter OTP
          </Typography>
        </Stack>
        <TextField
          size="small"
          id="otp"
          name="otp"
          sx={{ width: "100%", marginTop: "20px" }}
          value={otp}
          placeholder="Enter OTP here"
          onChange={(e) => {
            if (e.target.value.length) {
              setOtpError("");
            }
            if (e.target.value.length <= 6) {
              setOtp(e.target.value);
            }
          }}
          type="tel"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              submitOtp();
            }
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        {timer > 0 && (
          <Stack direction={"row"} justifyContent="center">
            <Typography fontSize={"16px"} mt={"15px"}>
              {`${addLeadingZeros(Math.floor(timer / 60), 2)}:${addLeadingZeros(
                timer % 60,
                2
              )} mins`}
            </Typography>
          </Stack>
        )}
        <Typography variant="body2" mt={2} color="text.secondary">
          An OTP will be sent to your mobile number linked with Aadhaar
        </Typography>
        {otpError && (
          <Typography variant="body2" mt={1} color="error">
            {otpError}
          </Typography>
        )}
      </Box>
      <Stack spacing={3}>
        {apiError && (
          <Stack
            spacing={1}
            justifyContent="space-around"
            alignItems="center"
            px={5}
          >
            <Typography variant="body2" textAlign="center">
              There seems to be an issue in OTP verification, please wait or you
              can start again
            </Typography>
            <Link href="/">Restart KYC</Link>
          </Stack>
        )}
        <PrimaryButton
          variant="contained"
          disabled={otpLoading || attempt > 2 || !timer}
          onClick={() => submitOtp()}
        >
          {otpLoading ? (
            <CircularProgress size={30} color="secondary" />
          ) : (
            "Submit"
          )}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};

const AdhaarKyc = () => {
  const [data, setData] = useState({});
  const [currentScreen, setCurrentScreen] = useState("consent");
  const screens = ["consent", "captcha", "otp"];

  return (
    <BaseContainer backgroundcolor="background.paper">
      <TitleBar title={"Paperless Aadhaar KYC"} />
      {currentScreen === "consent" && (
        <ConsentScreen setCurrentScreen={setCurrentScreen}></ConsentScreen>
      )}
      {currentScreen === "captcha" && (
        <CaptchaScreen
          setCurrentScreen={setCurrentScreen}
          setData={setData}
          hvSessionId={data.hvSessionId || ""}
        ></CaptchaScreen>
      )}
      {currentScreen === "otp" && (
        <OTPScreen
          setCurrentScreen={setCurrentScreen}
          hvSessionId={data.hvSessionId || ""}
        ></OTPScreen>
      )}
    </BaseContainer>
  );
};

export default AdhaarKyc;
