import { Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BaseContainer,
  SuccessComponent,
  TitleBar,
} from "../components/common";
import AppContext from "../contexts/AppContext";

const DigilokerError = ({ title }) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("Something went wrong");
  const { enqueueSnackbar } = useSnackbar();

  const getModule = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/module`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      json.errors.length > 0 && setErrorMsg(json.errors[0].message);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const getRedirect = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/session/redirect`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      window.open(json.url, "_self");
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  useEffect(() => {
    getModule();

    setTimeout(() => {
      getRedirect();
    }, 5000);
  }, []);

  return (
    <BaseContainer>
      <TitleBar title={"Paperless Aadhaar KYC"} />
      <Stack justifyContent="space-around" height="100%">
        <SuccessComponent title={errorMsg} success={false} />
        <Stack
          spacing={1}
          justifyContent="space-around"
          alignItems="center"
          px={5}
        >
          <Typography variant="body2" textAlign="center">
            {context.selfieRejectionMessage || ""}
          </Typography>
          <Button variant="text" onClick={getRedirect}>
            Go Back
          </Button>
        </Stack>
      </Stack>
    </BaseContainer>
  );
};

export default DigilokerError;
