import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import {
  BaseContainer,
  SuccessComponent,
  TitleBar,
} from "../components/common";
import { useHistory } from "react-router-dom";

const Success = ({ title, redirect }) => {
  const history = useHistory();

  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        history.replace("/success-kyc");
      }, 2000);
    }
  }, []);

  return (
    <BaseContainer>
      <TitleBar title={"Paperless Aadhaar KYC"} />
      <Stack justifyContent="space-around" height="80%">
        <SuccessComponent title={title} success={true} />
      </Stack>
    </BaseContainer>
  );
};

export default Success;
