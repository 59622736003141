import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BaseContainer, TitleBar } from "../components/common";
import { useHistory } from "react-router-dom";
import SuccessTick from "../assets/images/lottie/Success-tick.json";
import { Box } from "@mui/system";
import Lottie from "lottie-react";

const KycStatus = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.replace("/success-kyc");
    }, 3000);
  }, []);

  return (
    <BaseContainer>
      <TitleBar title={"Paperless Aadhaar KYC"} />
      <Stack
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <List
          sx={{ width: "90%", border: "1px solid #F3F3F3", paddingTop: "0" }}
        >
          <ListItem sx={{ backgroundColor: "#F7F7F7" }}>
            <Typography variant="h6">KYC Status</Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Selfie uploaded" />
            <Box
              sx={{
                width: "1.4rem",
                display: "block",
                paddingTop: "6px",
              }}
            >
              <Lottie animationData={SuccessTick} loop={false} />
            </Box>
          </ListItem>
          <ListItem>
            <ListItemText primary="Aadhaar KYC done" />
            <Box
              sx={{
                width: "1.4rem",
                display: "block",
                paddingTop: "6px",
              }}
            >
              <Lottie animationData={SuccessTick} loop={false} />
            </Box>
          </ListItem>
        </List>
      </Stack>
    </BaseContainer>
  );
};

export default KycStatus;
