import {
  Grid,
  Stack,
  Typography,
  CircularProgress,
  Popover,
} from "@mui/material";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import capture from "../assets/images/capture.svg";
import flash from "../assets/images/flash.svg";
import loop from "../assets/images/loop.svg";
import { BaseContainer, PrimaryButton, TitleBar } from "../components/common";
import AppContext from "../contexts/AppContext";

const Selfie = () => {
  const history = useHistory();
  const context = useContext(AppContext);
  const [popoverContent, setPopoverContent] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [callbackStatus, setCallbackStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const handlePopoverOpen = (event, content = "") => {
    setPopoverContent(content);
    setAnchorEl(event.currentTarget);
  };
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [cameraError, setCameraError] = useState(null);
  const open = Boolean(anchorEl);

  // Functions
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const videoConstraints = {
    width: 372,
    height: 416,
  };

  const captureImage = useCallback(() => {
    setImageSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  // call all api's

  const uploadSelfie = async () => {
    setLoading(true);
    let formData = new FormData();
    let file = dataURLtoFile(imageSrc, "selfie.jpeg");
    formData.append("file", file);
    formData.append("mediaType", "Selfie");
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      body: formData,
      headers: {
        token: context.token,
      },
    };
    var res = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/kyc/session/selfie/",
      requestOptions
    );
    let json = await res.json();

    if (res.ok) {
      history.replace("/success");
    } else {
      history.replace("/error");
    }
    setLoading(false);
  };

  return (
    <BaseContainer>
      <>
        <TitleBar
          title={imageSrc ? "Review your Selfie" : "Take your selfie"}
        />
        <Stack height="100%" justifyContent="space-between" py={3} pb={0}>
          <Typography textAlign={"center"} fontSize="13px">
            {imageSrc
              ? "Ensure that the photo is not blurred, not glazed and inside the frame"
              : "Ensure that your face is inside the frame. Take the photo under bright light and remove your spectacles and mask."}
          </Typography>
          {imageSrc === null && (
            <>
              {cameraError ? (
                <Stack
                  justifyContent="space-between"
                  alignItems={"center"}
                  spacing={2}
                  px={3}
                >
                  <b>
                    Make sure camera permissions are allowed for the
                    Application/Browser
                  </b>
                  <ul>
                    <li>
                      <b>App Permission</b>
                      <br />
                      Settings &gt; Applications &gt; Browser/Application &gt;
                      Permissions &gt; allow camera permission
                    </li>
                    <li>
                      <b>Browser Permission</b>
                      <br />
                      Browser Settings &gt; Camera permission
                    </li>
                  </ul>
                </Stack>
              ) : (
                <Grid item margin={"auto"}>
                  <Webcam
                    style={{ borderRadius: "50%" }}
                    ref={webcamRef}
                    forceScreenshotSourceSize={true}
                    screenshotQuality={1}
                    imageSmoothing={true}
                    screenshotFormat="image/jpeg"
                    onUserMediaError={(e) => {
                      setCameraError(e);
                    }}
                    videoConstraints={{
                      facingMode: "user",
                      width: 300,
                      height: 300,
                    }}
                  ></Webcam>
                </Grid>
              )}
              <Grid
                container
                justifyContent="space-around"
                alignItems="center"
                py={2}
              >
                {/* <Grid item>
                  <img
                    src={flash}
                    alt="flash"
                    onMouseEnter={(event) =>
                      handlePopoverOpen(event, "Turn on flash")
                    }
                    onMouseLeave={handlePopoverClose}
                  ></img>
                </Grid> */}
                <Grid item sx={{ cursor: "pointer" }}>
                  <img
                    src={capture}
                    alt="capture"
                    onClick={() => captureImage()}
                    onMouseEnter={(event) =>
                      handlePopoverOpen(event, "Take Selfie")
                    }
                    onMouseLeave={handlePopoverClose}
                  ></img>
                </Grid>
                {/* <Grid item>
                  <img
                    src={loop}
                    alt="loop"
                    onMouseEnter={(event) => {
                      let message = isRearCamera
                        ? "Turn on front Camera"
                        : "Turn on Rear Camera";
                      handlePopoverOpen(event, message);
                    }}
                    onMouseLeave={handlePopoverClose}
                    onClick={() => setIsRearCamera((pre) => !pre)}
                  ></img>
                </Grid> */}
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1 }}>{popoverContent}</Typography>
                </Popover>
              </Grid>
            </>
          )}

          {imageSrc && (
            <>
              <img
                src={imageSrc}
                alt="selfie"
                style={{ width: "75%", margin: "auto" }}
              ></img>
              <Stack
                justifyContent="space-between"
                alignItems={"center"}
                spacing={3}
              >
                <PrimaryButton
                  variant="contained"
                  disabled={loading}
                  onClick={() => uploadSelfie()}
                >
                  {loading ? (
                    <CircularProgress size={30} color="secondary" />
                  ) : (
                    "Use this Image"
                  )}
                </PrimaryButton>
                <Stack alignItems={"center"}>
                  <Typography color="text.tertiary" fontSize="18px">
                    Image is not clear?
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="text.error"
                    fontSize="16px"
                    fontWeight="500"
                    onClick={() => setImageSrc(null)}
                  >
                    Retake Selfie
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </>
    </BaseContainer>
  );
};

export default Selfie;
