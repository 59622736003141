import { CircularProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BaseContainer } from "../components/common";
import AppContext from "../contexts/AppContext";

const GetToken = () => {
  const history = useHistory();
  const context = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const getQueryId = () => {
    let query = window.location.search.substring(1); //"app=article&act=news_content&aid=160990"
    let vars = query.split("&"); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    const returnValues = {};

    // get state Id and status
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("="); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == "state") {
        returnValues["state"] = pair[1];
      }
      if (pair[0] === "status") {
        returnValues["status"] = pair[1];
      }
    }

    return returnValues;
  };

  const callDigilocker = async (token) => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        token: token,
      },
      body: JSON.stringify({}),
    };
    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/session/digilocker/url/`,
      requestOptions
    );
    const json = await res.json();

    if (res.ok) {
      window.open(json.url, "_self");
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const redirectToPage = (status, token) => {
    if (status.currentModule.moduleName === "SELFIE") {
      history.replace("/selfie");
    }
    if (status.currentModule.moduleName === "FAILURE") {
      history.replace("/digilocker-error");
    }
    if (status.currentModule.moduleName === "CONSENT") {
      history.replace("/aadhaar-consent");
    }
    if (status.currentModule.moduleName === "COMPLETED") {
      history.replace("/success-kyc");
    }
    if (status.currentModule.moduleName === "DIGILOCKER") {
      callDigilocker(token);
    }
  };

  const getModule = async (token) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/module`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      redirectToPage(json, token);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const getDigilockerCallback = async (state, status, token) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/session/callback?state=${state}&status=${status}`,
      requestOptions
    );

    const json = await res.json();
  };

  const requestToken = async (state, status) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "x-api-key": "9bdca9fc-7287-49f6-b9c5-27d47fddf4a9",
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/session/continue?journeyID=${id}`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      context.setToken(json.token);
      if (state && status) {
        await getDigilockerCallback(state, status, json.token);
        await getModule(json.token);
      } else {
        getModule(json.token);
      }
    } else {
      history.replace("/session-expired");
    }
  };

  useEffect(() => {
    const { state, status } = getQueryId();
    context.token && context.setToken("");
    requestToken(state, status);
  }, []);

  return (
    <BaseContainer>
      <Stack height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={100} color="secondary" />
      </Stack>
    </BaseContainer>
  );
};

export default GetToken;
