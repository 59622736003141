import React, { useState, useEffect } from "react";
const AppContext = React.createContext();

const getToken = () => {
  let token = "";
  try {
    token = localStorage.getItem("token") || "";
  } catch (error) {
    console.log("Token issue", error);
  }
  return token;
};

export const AppProvider = (props) => {
  const [token, sToken] = useState(getToken());
  const [selfieRejectionMessage, setSelfieRejectionMessage] = useState("");

  const setToken = (token) => {
    sToken(token);
    try {
      localStorage.setItem("token", token);
    } catch (error) {
      console.log("Failed to set localstorage token");
    }
  };
  return (
    <AppContext.Provider
      value={{
        token,
        setToken,
        selfieRejectionMessage,
        setSelfieRejectionMessage,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
