import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { BaseContainer, PrimaryButton, TitleBar } from "../components/common";
import AppContext from "../contexts/AppContext";

const AadhaarConsent = () => {
  const [language, setLanguage] = useState("English");
  const [allLanguages, setAllLanguages] = useState([]);
  const [consent, setConsent] = useState("");
  const [requestId, setRequestId] = useState("");
  const [subRequestId, setSubRequestId] = useState("");
  const theme = useTheme();

  const context = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const getDefaultConsent = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/consent/`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      setConsent(json.consent);
      setRequestId(json.requestID);
      setSubRequestId(json.subRequestID);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    return { reqID: json.requestID, subReqID: json.subRequestID };
  };

  const getLanguageConsent = async (lang) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/consent?language=${lang}&requestID=${requestId}&subRequestId=${subRequestId}`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      setConsent(json.consent);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const getAllLanguages = async (reqId, subReqId) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/consent/languages?requestID=${reqId}&subRequestId=${subReqId}`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      setAllLanguages(json.languages);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const submitConsent = async () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        token: context.token,
      },
      body: JSON.stringify({
        language: language,
        consentAction: "yes",
        consentMessage: consent,
        requestID: requestId,
        subRequestID: subRequestId,
      }),
    };
    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/consent/`,
      requestOptions
    );
    const json = await res.json();

    if (res.ok) {
      callDigilocker();
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const callDigilocker = async () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        token: context.token,
      },
      body: JSON.stringify({}),
    };
    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/session/digilocker/url/`,
      requestOptions
    );
    const json = await res.json();

    if (res.ok) {
      window.open(json.url, "_self");
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const languageChangeHandler = (e) => {
    setLanguage(e.target.value);
    getLanguageConsent(e.target.value);
  };

  useEffect(() => {
    (async () => {
      const { reqID, subReqID } = await getDefaultConsent();
      await getAllLanguages(reqID, subReqID);
    })();
  }, []);

  return (
    <BaseContainer backgroundcolor="background.paper">
      <TitleBar title={"Paperless Aadhaar KYC"} />
      <Stack justifyContent="space-between" height="100%" py={1}>
        <Stack spacing={1}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "15px",
              marginTop: "10px",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography fontSize={"20px"} fontWeight={500}>
                Aadhaar Consent
              </Typography>
              <Select
                size="small"
                value={language}
                onChange={(e) => languageChangeHandler(e)}
              >
                {allLanguages.map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {lang}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Box
              sx={{
                border: "1px solid " + theme.palette.background.paper,
                padding: "8px",
                minHeight: "300px",
                overflow: "scroll",
                marginTop: "10px",
              }}
            >
              {consent ? (
                <Typography variant="body2">{consent}</Typography>
              ) : (
                <CircularProgress size={30} color="secondary" />
              )}
            </Box>
          </Box>
        </Stack>
        <Stack spacing={2}>
          <PrimaryButton variant="contained" onClick={submitConsent}>
            Submit
          </PrimaryButton>
        </Stack>
      </Stack>
    </BaseContainer>
  );
};

export default AadhaarConsent;
