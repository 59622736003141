import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#123262",
    },
    secondary: {
      main: "#EF0101",
    },
    background: {
      paper: "#E5E5E5",
    },
    info: {
      main: "#292929",
      light: "#b2b2b2",
    },
    text: {
      primary: "#102C4C",
      secondary: "#A7B4C3",
      tertiary: "#3E3E3E",
      success: "#24CA7A",
      error: "#EF0101",
    },
    success: {
      main: "#24CA7A",
    },
    error: {
      main: "#EF0101",
    },
  },
});
