import { Stack, Typography, Link, Button } from "@mui/material";
import Lottie from "lottie-react";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import livenessLoader from "../assets/liveness.json";
import { BaseContainer, TitleBar } from "../components/common";
import AppContext from "../contexts/AppContext";

const Liveness = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(AppContext);
  const [showError, setShowError] = useState(false);

  const getLivenessStatus = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/kyc/okyc/kycStatus?service_name=selfie",
      requestOptions
    );
    const json = await res.json();
    if (res.ok) {
      return json;
    } else {
      setShowError(true);
      enqueueSnackbar(
        "Your request cannot be processed now due to technical issue. Please try again later.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
    }
  };

  const getRedirect = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        token: context.token,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/kyc/session/redirect`,
      requestOptions
    );

    const json = await res.json();

    if (res.ok) {
      history.replace(json.url);
    } else {
      enqueueSnackbar(json.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const pollWaitStatus = () => {
    setTimeout(async () => {
      let { KYCStatus, RejectReason } = await getLivenessStatus();
      if (KYCStatus === "SUCCESS") {
        history.replace("/success");
      } else if (KYCStatus === "FAILURE") {
        context.setSelfieRejectionMessage(RejectReason);
        history.replace("/error");
      } else {
        pollWaitStatus();
      }
    }, 4000);
  };

  useEffect(() => {
    pollWaitStatus();
  }, []);

  return (
    <BaseContainer>
      <TitleBar title={"Please wait ..."} />
      <Stack height="100%" justifyContent="space-around">
        <Stack spacing={3} px={5} height="65%" justifyContent="space-around">
          <Lottie animationData={livenessLoader} loop={true} />
          <Typography fontSize="20px" fontWeight="500" textAlign="center">
            Selfie liveness check is under process
          </Typography>
        </Stack>
        {showError && (
          <Stack
            spacing={1}
            justifyContent="space-around"
            alignItems="center"
            px={5}
          >
            <Typography variant="body2" textAlign="center">
              There seems to be a delay in liveness verification, please wait or
              you can start again
            </Typography>
            <Button variant="outlined" onClick={getRedirect}>
              Restart KYC
            </Button>
          </Stack>
        )}
      </Stack>
    </BaseContainer>
  );
};

export default Liveness;
